<template>
    <div class="login-container-wrapper">
        <s-form role="form" @submit="onSubmit" class="login-container bs-5">
            <h2>Login</h2>
            <small>Login with your Credential</small>
            <validated-input v-model="model.username" type="text" label="Username"/>
            <validated-input v-model="model.password" type="password" label="Password"/>
            <div class="bg-danger p-3" style="text-align: center;" v-if="errorMessage" v-html="errorMessage"></div>
            <btn color="primary mr-2" @click="onSubmit">LOGIN</btn>
            <btn @click="NewUser" design="basic-b" color="primary">Register</btn>
        </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name: 'Login',
    props: {
        info_message: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            loading: false,
            errorMessage: '',
            model: {
                username: '',
                password: ''
            }
        };
    },
    methods: {
        ...mapActions(['setUser', 'setCart']),
        onSubmit () {
            const that = this;
            axios.form(urls.auth.login, this.model).then(function (response) {
                const json = response.data;
                if (json.Success === 'true') {
                    console.log(json.user[0].role);
                    that.setUser(json.user[0]);
                    if (json.user[0].role === 'Shop Admin') {
                        that.$router.push({ path: '/admin/catalog/' });
                    } else {
                        that.$router.push({ path: '/' });
                    }
                } else {
                    that.errorMessage = json.errors.__all__ || '';
                }
                that.loading = false;
                that.cart();
            }).catch(function () {
                that.errorMessage = 'Invalid credentials.';
                that.loading = false;
            });
        },
        NewUser () {
            this.$router.push({ path: '/register/' });
        },
        async cart () {
            const items = await axios.get(urls.cart.cartdetails);
            this.setCart(items);
        }
    }
};
</script>
